/*
* 语言文件 en：英文
* */
export const lang_en = {
    '添加收货地址': '',
    '请输入收货人姓名': '',
    '请选择所在地区': '',
    '详细地址': '',
    '请输入详细地址': '',
    '设为默认地址': '',
    '取 消': '',
    '确 定': '',
    '欢迎语': '',
    '添加欢迎语': '',
    '序号': '',
    '排序': '',
    '默认使用': '',
    '所在地区': '',
    '手机号': '',
    '请输入手机号': '',
    '确定': '',
    '取消': '',
    '删除': '',
    '暂无欢迎语~': '',
    '请输入欢迎语内容': '',
    '最多输入50字': '',
    '输入排序': '',
    '请输入排序': '',
    '请输入0~255的数字，数值越小，显示越靠前': '',
    '启用': '',
    '购物车中还没有商品，赶紧选购吧': '',
    '最新加入的商品': '',
    '共': '',
    '件商品': '',
    '去购物车': '',
    '常见问题': '',
    '添加常见问题': '',
    '问题': '',
    '回答': '',
    '显示': '',
    '暂无常见问题~': '',
    '请输入常见问题': '',
    '解决回答': '',
    '请输入解决回答': '',
    '最多输入100字': '',
    '聊天记录': '',
    '平台': '',
    '商家': '',
    '商家:': '',
    '请选择商家': '',
    '客服:': '',
    '请选择客服': '',
    '消息内容': '',
    '请输入消息内容': '',
    '聊天日期': '',
    '开始日期': '',
    '结束日期': '',
    '查询': '',
    '搜索最近联系人': '',
    '搜索': '',
    '暂无数据～': '',
    '在线中': '',
    '确定结束与该会员的聊天吗？': '',
    '￥': '',
    '订单号': '',
    '已读': '',
    '未读': '',
    '常用语快捷回复': '',
    '暂未设置快捷回复~': '',
    '发送': '',
    '在线': '',
    '暂无在线客服~': '',
    '请选择访客开启对话～': '',
    '用户订单': '',
    '用户足迹': '',
    '店铺商品': '',
    '发送链接': '',
    '暂无订单～': '',
    '暂无足迹～': '',
    '暂无商品～': '',
    '¥': '',
    '折': '',
    '使用规则': '',
    '已抢': '',
    '已抢光': '',
    '立即领取': '',
    '已领取': '',
    '版权所有': '',
    '提供技术支持': '',
    '商户-客服助手': '',
    '平台-客服助手': '',
    '返回管理后台': '',
    '退出登录': '',
    '对话平台': '',
    '快捷回复': '',
    '聊天设置': '',
    '编辑': '',
    '向上滑动浏览更多数据': '',
    '数据加载中...': '',
    '数据加载完毕~': '',
    '账号登录': '',
    '手机登录': '',
    '请输入账号/手机号': '',
    '请输入密码': '',
    '请输入验证码': '',
    's后获取': '',
    '获取验证码': '',
    '登录': '',
    '立即注册': '',
    '忘记密码': '',
    '首页': '',
    '个人中心': '',
    '账户管理': '',
    '会员信息': '',
    '账号安全': '',
    '手机号管理': '',
    '邮箱管理': '',
    '登录密码': '',
    '支付密码': '',
    '重置密码': '',
    '收货地址': '',
    '消息': '',
    '请输入关键词': '',
    '我的购物车': '',
    '产品分类': '',
    '您好，欢迎来到': '',
    '退出': '',
    '注册': '',
    '商城首页': '',
    '我的订单': '',
    '待支付订单': '',
    '待收货订单': '',
    '待评价订单': '',
    '我的收藏': '',
    '商品收藏': '',
    '店铺收藏': '',
    '我的足迹': '',
    '我的账户': '',
    '我的优惠券': '',
    '我的余额': '',
    '官方客服': '',
    '服务中心': '',
    '新消息声音提醒': '',
    '保存': '',
    '添加快捷回复语': '',
    '快捷回复语': '',
    '暂无快捷回复内容~': '',
    '请输入快捷回复语内容': '',
    '热门推荐': '',
    '已售': '',
    '暂时没有数据～': '',
    '人购买': '',
    '立即抢购': '',
    '查看更多': '',
    '此处添加【186*340】图片': '',
    '此处添加【172*106】图片': '',
    '此处添加【187*120】图片': '',
    '此处添加【360*560】图片': '',
    '此处添加商品': '',
    '请选择商品，数量建议选择3的倍数，最少3个，最多12个': '',
    '已申请数量:': '',
    '立即申请': '',
    '请上传500*80的图片': '',
    '平台还未设置公告': '',
    '平台还未设置资讯': '',
    '请选择供货商，数量建议选择5的倍数，最少5个，最多20个': '',
    '收货人': '',
    '请输入收货人': '',
    '请输入2~25个字符': '',
    '不能为空': '',
    '值应为0~255的整数值': '',
    '排序值不为空': '',
    '修改成功': '',
    '删除成功': '',
    '编辑欢迎语回复': '',
    '添加欢迎语回复': '',
    '回答不能为空': '',
    '排序值不能为空': '',
    '编辑常见问题': '',
    '请先选择会员': '',
    '请先选择客服': '',
    '[表情]': '',
    '[图片]': '',
    '[商品]': '',
    '[订单]': '',
    '超过最大字符限制': '',
    '转接成功': '',
    '请选择图片类型文件': '',
    '是否要发送剪切板的图片?': '',
    '提示': '',
    '确认': '',
    '请输入正确的账号/手机号': '',
    '请输入短信验证码': '',
    '交易中心': '',
    '交易评价/晒单': '',
    '关注中心': '',
    '关注店铺': '',
    '客户服务': '',
    '我的退款': '',
    '我的退货': '',
    '会员中心': '',
    '我的钱包': '',
    '账户充值': '',
    '消息列表': '',
    '接收设置': '',
    '编辑快捷语回复': '',
    '添加快捷语回复': '',
    '我们正在努力装修中，敬请期待～': '',
    '确定删除此内容吗？': '',
    '操作': '',
};
